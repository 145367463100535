.secondary,
.tertiary,
.meta {
  color: var(--clr-neutral-500);
  font-weight: var(--text-weight-normal);
}

.tertiary {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}

p.ellipsisOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: var(--spacing-1);
}
