.form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
}

.form.modal {
  width: var(--width-128);
  max-width: 100%;
}

.form.modal.narrow {
  width: var(--width-80);
}

.form.modal.wide {
  width: var(--width-160);
}

.form.noGap {
  gap: unset;
}
