.dynamicTable {
  width: fit-content;
  background-color: var(--clr-neutral-200);
  border-collapse: separate;
  border-spacing: 1px;
}

.cell {
  text-align: start;
  padding: var(--spacing-1);
  overflow: hidden;
  box-sizing: border-box;
  background-color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header.cell {
  background-color: var(--clr-neutral-100);
  white-space: initial;
}

.row.hoverable {
  cursor: pointer;
}

.row.hoverable:hover .cell {
  background-color: var(--clr-sky-100);
}

.cell.hoverable {
  cursor: pointer;
}

.cell.inactive {
  pointer-events: none;
}

.row .cell.hoverable:hover,
.row.hoverable:hover .cell.hoverable:hover {
  background-color: var(--clr-sky-200);
  outline: 1px solid var(--clr-sky-700);
}

.cell.red,
.red {
  background-color: var(--clr-red-200);
}

.cell.yellow,
.yellow {
  background-color: var(--clr-yellow-200);
}

.cell.green,
.green {
  background-color: var(--clr-green-200);
}
