.dropdownSelector {
  display: flex;
  padding: var(--spacing-1) var(--spacing-3);
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  border: 1px solid var(--clr-neutral-300);
  border-radius: var(--rounded-full);
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.chipSelector {
  flex-wrap: wrap;
}

.selectorOption {
  padding: var(--spacing-1) var(--spacing-3);
  cursor: pointer;
}

.selectorOption.selected {
  background-color: var(--clr-neutral-200);
}

.dropdownLabel {
  width: var(--width-28);
  color: var(--clr-neutral-500);
  white-space: nowrap;
  flex-shrink: 0;
}
