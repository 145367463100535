.group {
  display: flex;
  align-items: center;
}

.group.page {
  gap: var(--spacing-4);
}

.group.header {
  flex-grow: 1;
  flex-direction: var(--header-group-direction);
  align-items: var(--header-group-align);
}

.group.subheader {
  margin-bottom: var(--spacing-6);
}

.group.bundle {
  gap: var(--spacing-2);
}

.group.bundle.vertical {
  gap: var(--spacing-1);
  flex-direction: column;
  align-items: unset;
}

.group.split {
  flex-grow: 1;
  justify-content: space-between;
}

.group.fixedWidth {
  flex-shrink: 0;
  flex-grow: 0;
}

.group.width-2 {
  width: var(--width-2);
}

.group.width-4 {
  width: var(--width-4);
}

.group.width-8 {
  width: var(--width-8);
}

.group.width-10 {
  width: var(--width-10);
}

.group.width-12 {
  width: var(--width-12);
}

.group.width-16 {
  width: var(--width-16);
}

.group.width-20 {
  width: var(--width-20);
}

.group.width-24 {
  width: var(--width-24);
}

.group.width-28 {
  width: var(--width-28);
}

.group.width-32 {
  width: var(--width-32);
}

.group.width-36 {
  width: var(--width-36);
}

.group.width-40 {
  width: var(--width-40);
}

.group.width-44 {
  width: var(--width-44);
}

.group.width-48 {
  width: var(--width-48);
}

.group.width-52 {
  width: var(--width-52);
}

.group.width-56 {
  width: var(--width-56);
}

.group.width-64 {
  width: var(--width-64);
}

.group.width-68 {
  width: var(--width-68);
}

.group.width-72 {
  width: var(--width-72);
}

.group.width-80 {
  width: var(--width-80);
}

.group.width-96 {
  width: var(--width-96);
}

.group.alignRight {
  justify-content: flex-end;
}

.group.centered {
  justify-content: center;
}

.group.bundle.vertical.centered {
  align-items: center;
}

.group.bundle.vertical.alignLeft {
  align-items: flex-start;
}

.group.alignTop {
  align-items: flex-start;
}
