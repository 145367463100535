.input {
  padding: var(--spacing-1) var(--spacing-3);
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  border: 1px solid var(--clr-neutral-300);
  border-radius: var(--rounded);
  flex-grow: 1;
  width: 100%;
}

.checkbox {
  flex-grow: 0;
  accent-color: var(--clr-sky-700);
  width: var(--text-base-height);
  height: var(--text-base-height);
  margin: 0;
  cursor: pointer;
}

.input:focus {
  outline-color: var(--clr-sky-700);
}

.inputWithLabel {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
}

.inputLabel {
  width: var(--width-28);
  color: var(--clr-neutral-500);
  white-space: nowrap;
  flex-shrink: 0;
}

.checkboxWithLabel {
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: var(--spacing-2);
}

.invalid {
  border-color: var(--clr-red-700);
}

.invalid:focus {
  outline-color: var(--clr-red-700);
}
