.tabs {
  display: flex;
  flex-grow: 1;
  border-bottom: 1px solid var(--clr-neutral-300);
}

.tab {
  display: flex;
  padding: var(--spacing-3) var(--spacing-6);
  padding-bottom: var(--spacing-2);
  cursor: pointer;
  border-bottom: var(--spacing-1) solid transparent;
  border-radius: var(--rounded) var(--rounded) 0 0;
  gap: var(--spacing-2);
}

a.tab {
  text-decoration: none;
  color: var(--clr-neutral-900);
}

.tab:hover {
  background-color: var(--clr-neutral-100);
}

.tab.selected {
  border-color: var(--clr-sky-700);
}
